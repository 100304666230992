import Bowser from 'bowser';
const browser = Bowser.getParser(window.navigator.userAgent);
const browserName = browser.getBrowserName().toLowerCase();

const SupportedBrowsers = {
  chrome: '>=72',
  firefox: '>=65',
  edge: '>=18',
  safari: '>=14',
  opera: '>=58',
};

const isIE = browserName.includes('ie');
const isValidBrowser = browser.satisfies(SupportedBrowsers);

if (!isValidBrowser || isIE) {
  if (window.location.pathname !== '/unsupported-browser/') {
    window.location.href = '/unsupported-browser/';
  }
}
